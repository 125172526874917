import React, {useState, useEffect} from 'react';
import { 
  Container,
  Box,
  Grid,
  useIsFocusVisible,
 } from "@material-ui/core";

import { useWindowSize } from './utils';

function YouTube() {
  const size = useWindowSize();

  return (
    <div className="youtube"
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: size.width > 600 ? "56.25%" : "100%",
        marginBottom: 32,
      }}
    >
      <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/wBIH788KnsQ" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
        }}
        ></iframe>
    </div>

  );
}

function Description() {
  return (
  <>
  <Container>
    <h1 style={{
      marginBottom: 16,
      fontWeight: 500,
      fontSize: 32,
    }}>あまりっこ動物</h1>

    <h2 style={{
      marginTop: 0,
      marginBottom: 16,
      fontWeight: 500,
      fontSize: 24,
    }}>Paper waste Toy AMARIKKO</h2>

    <p style={{
      fontSize: 16,
    }}>
      印刷は、その過程でたくさんのゴミが出ます。
      着目したのは、捨てられてしまう紙の端材にも、
      他の部分と変わらない手触りや匂いがあるということ。
      印刷の技術と人工知能を駆使して、ただの端材を子どもたちが紙に
      親しめるおもちゃ｢あまりっこ動物｣にうまれからせました。

      {/* 印刷業界では、年間4,659,000トンにも及ぶ端材が生まれている。
      製版会社のSHOEIは、意味のないものに価値を見出して遊ぶ子供の自由な発想力にヒントを得て、
      端材から生まれたおもちゃ｢あまりっこ動物｣を制作した。
      本施策では23種類の動物の形を深層学習し、端材が何の動物に似ているか判定。
      特殊印刷とレーザーにより、その動物のパターン柄を自動で配置、加工するシステムを開発した。
      学習の工程では、｢落書き｣のデータセット”The Quick, Draw! Dataset”を用いたことで、
      デフォルメされた動物のかたちを学習することに成功した。
      このシステムではすべての工程が自動化されており、人手をかけずに印刷工場に導入することが可能である。
      このシステムにより生まれた｢あまりっこ動物｣は、2019年3月の展示イベントで子どもたちに配布され、
      未来を担う子どもたちに紙の世界の大切さと豊かさを知ってもらう絶好の機会を作り出した。 */}
    </p>

    <p style={{
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 32,
    }}>
      第24回 メディア芸術祭 エンターテインメント部門 審査委員会推薦作品
    </p>

  </Container>

    <div className="hazaiImage"
      style={{
        textAlign: 'center',
      }}
    >
    <img src="/images/works/amarikko/hazai.jpg" alt="捨てられる端材"
      style={{
        width: '100%',
        maxWidth: 600,
        marginBottom: 32,
      }}
    ></img>

    </div>

  <Container>

    <h3 style={{marginTop: 0}}>作業工程</h3>

    <ol style={{
      paddingLeft: 20,
      marginBottom: 64,
    }}>
      <li>型抜き印刷で出た端材をカメラで読み込む。</li>
      <li>画像認識技術を用いて切りやすい部分を解析。効率的に端材を裁断する線を描く。</li>
      <li>
        Googleが公開している数万の落書きデータ”The Quick,Draw!Dataset”を元に、
        あらゆる形を23種類の動物として、解釈するよう人工知能に学習させる。
        その人工知能を用いて細かくなった端材それぞれが何の動物に似ているかを識別する。
      </li>
      <li>識別された動物の柄を端材に自動でレイアウトし、印刷データを生成する。 </li>
      <li>実際に端材へインクジェットプリンタで印刷し、CADで裁断する。 </li>
    </ol>
  </Container>
  </>
  );
}

function Images() {
  const imageNames = [
    "photo_amarikko_2.jpg",
    "photo_amarikko_3.jpg",
    "photo_amarikko_4.jpg",
    "photo_amarikko_5.jpg",
    "photo_amarikko_6.jpg",
    "photo_amarikko_8.jpg",
    "photo_amarikko_9.jpg",
    "photo_amarikko_10.jpg",
  ];

  return (
    <Grid container>
      { imageNames.map((imageName, i) => 
        <Grid item xs={12} sm={6} key={i}>
          <img src={`/images/works/amarikko/${imageName}`} 
            alt={`あまりっ子動物 サンプル画像 ${i}`}
            style={{
              width: '100%',
            }}
            />
        </Grid>
      ) }
    </Grid>
  );
}

function Credit() {

  const text = [
    "/*",
    "",
    "  credit",
    "",
    "*/",
    "",
    "",
    "let kom = koyanagi * murakami;",
    "",
    "kom.feat(takahiro_ohnishi);",
    "kom.feat(marina_danjyo);",
    "kom.feat(shunichi_yamashita);",
    "kom.feat(junpei_takahara);",
    "kom.feat(kotaro_nishitani);",
  ].join("\n");

  return <Container style={{
    paddingTop: 64,
    paddingBottom: 64,
  }}>
    <pre><code style={{
      fontFamily: 'Source Code Pro, sans-serif',
      fontWeight: 900,
    }}>
      {text}
    </code></pre>

  </Container>

}

export default function Amarikko() {
  return (
    <div className="Amarikko" style={{
      minHeight: '100vh',
      backgroundColor: 'white',
      paddingBottom: 16,
      color: "#20A364",
    }}>

      <YouTube />
      <Description />
      <Images />
      <Credit />
    </div>

  )
}
