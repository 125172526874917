import { useState, useEffect } from "react";
import { 
  Container,
  Grid,
  Box,
 } from "@material-ui/core";
import { useImperativeHandle } from "react";
import { useWindowSize } from "./utils";

function useCounter(offset: number, interval: number) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let counter = 0;
    let timer: NodeJS.Timer = null!;
    setTimeout(() => {
      timer = setInterval(() => {
        counter++;
        setCounter(counter);
      }, interval);
    }, offset);

    return () => clearInterval(timer);
  }, []);

  return counter;
}

const cursor = "|";

function Title() {
  const counter = useCounter(0, 60);
  const counter2 = useCounter(0, 400);
  const counter3 = useCounter(0, 1500);


  const text = [
    "/*",
    "",
    "  K0M",
    "",
    "*/"
  ].join('\n')

  let subText = text.slice(0, counter);
  if (counter < text.length) {
    subText += cursor;
  } else if ((counter2 % 2 === 0) && (counter3 < 1)) {
    subText += cursor;
  }

  return (
    <Container>
      <div style={{
        fontSize: 60,
        color: "#171C61",
        paddingLeft: 16,
      }}>
        <pre style={{margin: 0, height: 400}}>
          <code style={{ fontFamily: 'Source Code Pro, sans-serif', fontWeight: 900 }}>
            {subText}
          </code>
        </pre>
      </div>
    </Container>
  );
}

function CodeArea() {
  const counter = useCounter(1500, 16);
  const counter2 = useCounter(0, 400);
  const size = useWindowSize();
  // const counter3 = useCounter(0, 1500);

  const text = [
    "let kom = koyanagi * murakami;",
    "",
    "while (kom.wantsToMake()) {",
    "  let x = kom.search();",
    "  let output = kom.feat(x);",
    "  show(output);",
    "}",
  ].join("\n");

  let subText = text.slice(0, counter);
  if (counter > 0) {
    if (counter < text.length) {
      subText += cursor;
    } else if (counter2 % 2 === 0) {
      subText += cursor;
    }
  }

  return (
    <Container>
      <div style={{
        fontSize: Math.min(size.width / 25, 18),
        color: "#AD0D23",
        paddingLeft: 8,
        width: '100%',
        overflow: 'hidden',
      }}>
        <pre style={{margin: 0, height: 175}}>
          <code style={{ fontFamily: 'Source Code Pro, sans-serif', fontWeight: 900 }}>
            {subText}
          </code>
        </pre>
      </div>
    </Container>
  );
}

function Description() {
  return ( <Container style={{
    // padding: "0 16px",
    fontFamily: 'Source Code Pro, sans-serif',
    fontWeight: 500,
    color: "#171C61",
  }}>
      <p>
        K0Mは、アートディレクターの小柳祐介とクリエイティブテクノロジストの村上晋太郎によるクリエイティブユニットです。
      </p>
      <p>
        テクノロジーとデザインを起点に、様々な実験的プロジェクトを世の中に発信します。
      </p>
    </Container>
  );
}

function Links() {

  return (<Container style={{
    fontFamily: 'Source Code Pro, sans-serif',
    fontWeight: 900,
    color: "#171C61",
  }}>

    <p>K</p>

    <p><a href="https://www.koyanagi.work/" style={{ textDecoration: 'none', color: "#171C61" }}>
      &gt; koyanagi.website
    </a></p>
    <p><a href="https://www.instagram.com/koyanagi2/" style={{ textDecoration: 'none', color: "#171C61" }}>
      &gt; koyanagi.instagram
    </a></p>

    <p>M</p>
    <p><a href="https://smurakami.com" style={{ textDecoration: 'none', color: "#171C61" }}>
      &gt; murakami.website
    </a></p>
    <p><a href="https://twitter.com/M084sky" style={{ textDecoration: 'none', color: "#171C61" }}>
      &gt; murakami.twitter
    </a></p>
    
  </Container>)

}


export default function Top() {
  return (
    <div className="Top" style={{
      minHeight: '100vh',
      backgroundColor: '#FFE8D6',
    }}>
      <Box sx={{width: 1, height: 64}}></Box>
      <Title></Title>
      <Box sx={{width: 1, height: 32 * 3}}></Box>
      <CodeArea></CodeArea>
      <Box sx={{width: 1, height: 64}}></Box>
      <Description></Description>
      <Box sx={{width: 1, height: 64}}></Box>
      <Links></Links>
      <Box sx={{width: 1, height: 64}}></Box>
    </div>
  );
}