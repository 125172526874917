import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Amarikko  from './Amarikko';
import Top from './Top';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/works/amarikko">
          <Amarikko></Amarikko>
        </Route>
        <Route path="/">
          <Top></Top>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
